import React from 'react'
import Grid from "@mui/material/Grid"
import NewsFeed from './NewsFeed';
import './OpsCenter.css';
import { TVChartContainer } from './components/TVChartContainer'
import Watchlist from './Watchlist';
import Popout from './Popout';
import { v4 as uuidv4 } from 'uuid';
import Login from './Login';

class OpsCenter extends React.Component {    
  constructor(props) {
    super(props);

    this.state = {
      showExternalWatchlist: false,
      externalCharts: [],
      watchlistWindow: null,
      globalWatchlistLastUpdate: null,
    };

    this.toggleExternalWatchlist = this.toggleExternalWatchlist.bind(this);
    this.addExternalChart = this.addExternalChart.bind(this);
    this.removeExternalChart = this.removeExternalChart.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.setWatchlistWindow = this.setWatchlistWindow.bind(this);
    this.setWatchlistGlobalLastUpdate = this.setWatchlistGlobalLastUpdate.bind(this);
  }

  handleMessage(e) {
    try {
      let message_json = JSON.parse(e.data);
      if (message_json.user_id !== undefined && message_json.user_id !== '' && (this.state.mr_user_id === undefined || this.state.mr_user_id === 0)) {
        console.log('user id message received', message_json.user_id);
        this.setState({
          mr_user_id: message_json.user_id
        });
      } else {
        console.log('message received', message_json);
      }
    } catch (error) {
    }
  }

  componentDidMount() { 
    // this.setState({mr_user_id: 'test_user-1'});  //uncomment to test iframe   
    // Set up parent -> iframe (this app) messaging  
    window.addEventListener('message', this.handleMessage, false);
    
    // Send message to parent to show this window has loaded 
    window.parent.postMessage(JSON.stringify({message: 'iframe-loaded-to-zachs-mr-wp-sandbox.local-to-*'}), '*');
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage); 
  };

  toggleExternalWatchlist() {
    this.setState(state => ({
      showExternalWatchlist: !state.showExternalWatchlist,
    }), () => {});    
  }

  setWatchlistWindow(window_obj) {
    this.setState({
      watchlistWindow: window_obj
    })
  }

  setWatchlistGlobalLastUpdate() {
    this.setState({
      globalWatchlistLastUpdate: Date.now(),
    })
  }

  addExternalChart(symbol, interval) {
    this.setState(previousState => ({
      ...previousState,
      externalCharts: [...previousState.externalCharts, {
        key: uuidv4(),
        symbol: symbol,
        interval: interval
      }]
    }));
  }

  removeExternalChart(chart_key) {
    this.setState(previousState => ({
      ...previousState,
      externalCharts: previousState.externalCharts.filter(chart_obj => chart_obj.key !== chart_key)
    }));
  }

  render() {
    if(this.state.mr_user_id === undefined || this.state.mr_user_id  === 0) {
      console.log('Ops Center - render(): USER ID IS UNDEFINED', this.state.mr_user_id);
      return (
        <Login key={this.state.mr_user_id} />
      );
    } else {
      console.log('Ops Center - render(): USER ID IS SET', this.state.mr_user_id);
    };

    return (
      <Grid  
        container 
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        id='ops-center-container'
        style={{height: '100vh', width: '100vw'}}
        key={`${this.state.mr_user_id}--container`}
        spacing={2}
      >
        <Grid 
          item
          sm={0}
        >
          {this.state.showExternalWatchlist && (
            <Popout setWatchlistWindow={this.setWatchlistWindow}>
              <Watchlist 
                key={'watchlist-popout-' + this.state.mr_user_id + this.state.globalWatchlistLastUpdate} 
                userId={this.state.mr_user_id} 
                symbolList={this.state.watchlist} 
                isPopout={true} 
                openExternalWatchlist={this.toggleExternalWatchlist} 
                closeExternalWatchlist={this.toggleExternalWatchlist} 
                watchlistWindow={this.state.watchlistWindow} 
                setWatchlistGlobalLastUpdate={this.setWatchlistGlobalLastUpdate} 
                globalWatchlistLastUpdate={this.state.globalWatchlistLastUpdate} />
            </Popout>
          )}
        </Grid>
        <Grid 
          container
          item
          direction='row'
          justifyContent="flex-start"
          alignItems="flex-start"
          sm={10}
          style={{ height: '95vh', width: '100vw'}}
          spacing={2}
        >
          <Grid 
            item 
            sm={7}
            style={{ height: '95vh', width: '55vw'}}
          >                  
            <TVChartContainer userId={this.state.mr_user_id} symbol={'BINANCE:BTCUSD'} isPopout={false} addExternalChart={this.addExternalChart} removeExternalChart={this.removeExternalChart}/>
          </Grid>
          <Grid 
            item
            container 
            direction='column'
            justifyContent="flex-start"
            alignItems="flex-start"
            sm={5}
            style={{ height: '95vh', width: '45vw'}}
            spacing={2}
          >
            <Grid
              item
              sm={6}
              style={{ height: '46vh', width: '43vw'}}
            >
              <Watchlist 
                key={'watchlist-' + this.state.mr_user_id + this.state.globalWatchlistLastUpdate} 
                userId={this.state.mr_user_id} 
                isPopout={false} 
                openExternalWatchlist={this.toggleExternalWatchlist} 
                closeExternalWatchlist={this.toggleExternalWatchlist}
                setWatchlistGlobalLastUpdate={this.setWatchlistGlobalLastUpdate} 
              />
            </Grid>
            <Grid
              item
              sm={6}
              style={{ height: '40vh', width: '43vw'}}
            >
              <NewsFeed/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

}

export default OpsCenter;
