import * as React from 'react';
import './index.css';
import { widget } from '../../charting_library';
import binanceAPI from '../../trading_view/binance_chart_datafeed';
import SaveLoadAdapter from '../../trading_view/tv_save_load_adapter';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export class TVChartContainer extends React.PureComponent {
	static defaultProps = {
		// symbol: "Binance:BTCUSD",
		// interval: 'D',
		libraryPath: '/charting_library/',
    chartsStorageUrl: process.env.REACT_APP_BACKEND_URL,
		chartsStorageApiVersion: 'v1',
		clientId: process.env.REACT_APP_PARENT_URL,
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
    theme: 'dark'
	};

	tvWidget = null;

	constructor(props) {
		super(props);
    this.query_symbol = this.getQueryVariable('symbol');
    this.query_user_id = this.getQueryVariable('user');
    if (this.query_symbol === undefined) {
      this.query_symbol = this.props.symbol;
    }

    if (this.query_user_id === undefined) {
      this.query_user_id = this.props.userId;
    }

		this.ref = React.createRef();
    this.bfAPI = new binanceAPI({ debug: false });
    this.saveLoadAdapter = new SaveLoadAdapter({ debug: false, user_id: this.query_user_id })

    this.state = {
      base_symbol: 'BTC',
      quote_symbol: 'USD'
    };
	}

	componentDidMount() {
		const widgetOptions = {
      debug: false,
			symbol: this.query_symbol,
      datafeed: this.bfAPI,
			interval: this.props.interval,
			container: this.ref.current,
			library_path: this.props.libraryPath,
			locale: getLanguageFromURL() || 'en',
			disabled_features: ['header_fullscreen_button', 'header_screenshot', 'popup_hints'],
			enabled_features: ['study_templates'],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
      save_load_adapter: this.saveLoadAdapter,
			client_id: this.props.clientId,
			user_id: this.query_user_id,
			fullscreen: false,
			autosize: true,
      width: '80%',
      height: '60%',
			studies_overrides: this.props.studiesOverrides,
      theme: this.props.theme,
      auto_save_delay: 5,
      overrides: {
        'paneProperties.backgroundGradientStartColor': '#0F0F2B',
        'paneProperties.backgroundGradientEndColor': 'black',
      },
      custom_css_url: '/chart_styles.css',
      toolbar_bg: '#0F0F2B',
      loading_screen: {
        backgroundColor: '#0F0F2B',
        foregroundColor: '#4344C3'
      },
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {

      // tvWidget.chart().createStudy('TD Sequential', false, true);

      tvWidget.subscribe('onAutoSaveNeeded', () => {
        tvWidget.saveChartToServer(() => {
          console.log('chart autosaved')
        }, () => {
          console.log('failed to autosave chart')
        });
      });

      tvWidget.getSavedCharts((charts) => {
        if(charts.length > 0) {
          let chart = charts.sort((a, b) => (a.modified_iso < b.modified_iso ? 1 : -1 ))[0];
          tvWidget.loadChartFromServer(chart);
          console.log('last chart loaded');
        }
      });

			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to pop out chart');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => {
          if (this.props.isPopout) {
            window.close();
          } else {
            window.open(`${process.env.PUBLIC_URL}/chart?symbol=${this.props.symbol}&user=${this.props.userId}`, '', 'width=600,height=400,left=200,top=200');
          }
        });
				button.innerHTML = 'Pop Out';
			});
      const context = this;
      tvWidget
        .chart()
        .onSymbolChanged()
        .subscribe(null, function (symbolData) {
          const symbols = symbolData.description.split(' / ');
          context.setState({
            base_symbol: symbols[0],
            quote_symbol: symbols[1]
          });
          tvWidget.saveChartToServer(() => {
            console.log('chart saved')
          }, () => {
            console.log('failed to save chart')
          });
        });
      const symbols = tvWidget.chart().symbolExt().description.split(' / ');
      this.setState({
        base_symbol: symbols[0],
        quote_symbol: symbols[1]
      });
		});
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
  }

	render() {
		return (
      <>
        {this.props.isPopout ? (
          <div id='chart-pop-out-container'>
            <div
              ref={ this.ref }
              className={ 'TVChartContainer-popout' }
            />
          </div>
        ) : (
          <div id='chart-pop-in-container'>
            <div
                ref={ this.ref }
                className={ 'TVChartContainer' }
            />
          </div>
        )}
        <div id='tradingview-link-container' >
          <a id='tradingview-link' target='_blank' rel="noreferrer" href={`https://www.tradingview.com/symbols/${this.state.base_symbol}${this.state.quote_symbol}/`}>{this.state.base_symbol} Chart By TradingView</a>
        </div>
      </>
		);
	}
}